import { Component, OnInit } from '@angular/core';
import {TribesService} from '../../shared/services/tribes.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-manage-tribes',
  templateUrl: './manage-tribes.component.html',
  styleUrls: ['./manage-tribes.component.css', '../../../assets/css/styles.css']
})
export class ManageTribesComponent implements OnInit {
  public manageTribeList: any = [];
  public user: any = [];

  constructor(private tribeService: TribesService, private route: Router) {
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  ngOnInit(): void {
    this.fetchManageTribes();
  }

  fetchManageTribes(): void{
    this.tribeService.getManageTribes().subscribe(results => {
      const {tribes} = results;
      this.manageTribeList = tribes;
    }, error => {
      // this.toast.error(error1.error.error_description);
    });
  }

  seeJoinedTribes(id: number): void{
    this.route.navigate(['user/tribe-users/' + id]);
  }

  addTribeUsers(id: number): void{
    this.route.navigate(['user/add-tribe-users/' + id]);
  }
  // https://dev.socrai.com/api/add_user_to_tribe/{tribe_id}

}
